const itfFairCalenderProposalInfo = '/itf-trade-fair/fair-calander-proposal-info/'
export const itfFairCalenderProposalInfoList = itfFairCalenderProposalInfo + 'list'
export const itfFairCalenderListFiscalCircularWise = itfFairCalenderProposalInfo + 'collected-fair-calendars-fiscal-year-circular-wise'
export const itfFairCalenderProposalInfoStore = itfFairCalenderProposalInfo + 'store'
export const itfFairCalenderProposalInfoUpdate = itfFairCalenderProposalInfo + 'update'
export const getCalenderProposalInfo = itfFairCalenderProposalInfo + 'get-calender-info'
export const getDetailsCalanderList = itfFairCalenderProposalInfo + 'get-details-calender-list'
export const getFinalCalanderList = itfFairCalenderProposalInfo + 'get-final-calender-list'
export const getArchivedCalanderList = itfFairCalenderProposalInfo + 'get-archived-calender-list'
export const itfFairCalenderProposalInfoReject = itfFairCalenderProposalInfo + 'reject'
export const userListByDesignationApi = 'user/users-by-designation-id'
export const calenderForwardStoreApi = itfFairCalenderProposalInfo + 'forward'
export const itfFairCalenderProposalForwardList = itfFairCalenderProposalInfo + 'forward-list'
export const getForwardCommentsApi = itfFairCalenderProposalInfo + 'forward-comments-list'
export const calendarApproveApi = itfFairCalenderProposalInfo + 'approve'
export const itfFairCalenderApprovalList = itfFairCalenderProposalInfo + 'approved-list'
export const itfFairCalenderArchivedList = itfFairCalenderProposalInfo + 'archived-list'
export const fairCalanderInfoDetails = itfFairCalenderProposalInfo + 'get-calander-details'
